import React from 'react'

const Brands = () => {
   const brandImages = [
    'https://www.kalyanjewellers.net/images/kalyan_logo.svg',
    'https://qph.cf2.quoracdn.net/main-qimg-46e4cf54e93ad179ff19843dde22f4d6-lq',
    'https://1000logos.net/wp-content/uploads/2019/11/Hyatt-Logo.png',
    'https://logos-download.com/wp-content/uploads/2021/01/Nykaa_Logo.png',
    'https://upload.wikimedia.org/wikipedia/en/thumb/3/32/Michael_Kors_%28brand%29_logo.svg/1280px-Michael_Kors_%28brand%29_logo.svg.png',
    'https://zeevector.com/wp-content/uploads/2021/02/Croma-Logo-Vector.png',
    'https://seeklogo.com/images/C/cult-fit-logo-9020239928-seeklogo.com.png',
    'https://www.nicepng.com/png/detail/262-2621445_taj-hotel-logo-png.png',


   ] 
  return (
    <div className='py-10 px-10 flex flex-col justify-center items-center'>
        <div className='text-[#F30505] md:text-5xl md:leading-[53.34px] text-3xl font-bold'>Rewards From Brands you love!</div>
        <div className='md:mt-20 mt-8 md:grid md:grid-rows-2 md:grid-cols-4 md:gap-y-20 md:space-y-0 space-y-7 justify-between items-center  gap-x-20'>
            {brandImages.map((image)=>(
                <img className='lg:w-[237px] lg:h-[155px]  border border-black' src={image} alt=""/>
            ))}
        </div>
    </div>
  )
}

export default Brands