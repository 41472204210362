import React from 'react'

const Rewards = () => {
    return (
        <div className='bg-[#F5F4F4] py-20 md:px-0 px-10 flex flex-col justify-center items-center'>
            <div className='md:text-6xl text-3xl leading-[60px] font-normal'>
                Grab the <span className='lg:text-8xl text-4xl leading-[60px] text-[#F30505] font-bold'>Rewards</span> Now
            </div>
            <div className='font-medium text-xl md:px-0 px-2 md:text-2xl md:leading-5 mt-14'>Every purchase made by you or your client wins you Amazing Rewards!</div>
            <div className='text-base leading-5 md:px-0 px-2 font-light mt-4'>When you or your client purchases any product on Frikly, you will receive amazing rewards which you can redeem to get access to curated products and services.</div>
            <div className="bg-[#F30505] w-fit mt-16 md:px-20  md:py-[18px] px-7 py-4 rounded-2xl font-bold font-['Open_Sans'] text-white text-lg leading-[24.51px]">
                Explore Rewards
            </div>
        </div>
    )
}

export default Rewards