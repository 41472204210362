import React from 'react'

const WelcomeHome = () => {
  return (
    <div className='w-full md:py-32 py-20 flex justify-center items-center flex-col'>
        <div className='font-bold md:text-5xl md:leading-[48px] text-3xl'>Welcome Home !</div>
        <div className="bg-[#F30505] w-fit mt-10 px-20 py-2 rounded-2xl font-bold font-['Inter'] text-white md:text-2xl text-xl">
                join Now
            </div>
    </div>
  )
}

export default WelcomeHome