import React from 'react'

const Home = () => {
    return (
        <div className="bg-[url('/src/assets/homeBg.png')] h-[600px] flex flex-col items-center justify-center py-5 bg-no-repeat  bg-cover" >
            <div className=' w-full flex flex-col justify-center items-center md:text-4xl xl:text-7xl lg:text-6xl' >
                <div className='flex justify-center items-center w-full md'>
                    Frikly Professional is designed to help
                </div>
                <div className=' w-full flex justify-center items-center'><span className='text-[#F30505]'> Professionals &nbsp; </span> like you</div>
            </div>
            <div className="bg-[#F30505] w-fit mt-10 md:px-20 md:py-2 px-7 py-2 rounded-2xl font-bold font-['Inter'] text-white md:text-2xl text-xl">
                join Now
            </div>
        </div>
    )
}

export default Home