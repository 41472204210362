import React from 'react'
import Logo from '../assets/logo.svg'
import {MdMailOutline,MdAddCall} from 'react-icons/md'
import {FaFacebookF, FaInstagram,FaTwitter, FaLinkedin} from 'react-icons/fa'
const Footer = () => {
  return (
    <div className='flex xl:justify-center items-center flex-col bg-[#34A0A4] bg-opacity-[79%] px-6 py-20  '>
        <div className='bg-white w-full  flex flex-col rounded-[40px]  xl:items-center'>
            <div className='w-full border-b pb-10 border-black flex lg:flex-row flex-col mt-12  xl:gap-x-[500px] xl:justify-start lg:justify-between px-20 items-center  2xl:px-20 2xl:justify-between xl:pr-20 xl:px-80'>
                <div className=''>
                    <img className='w-full' src={Logo} alt=""/>
                </div>
                <div className='md:mt-0 mt-4'>
                    <div className='text-[#E67136] font-bold md:text-base md:leading-[16px]'>Contact</div>
                    <div className='md:mt-5 text-base'>
                        <div className="flex gap-x-3 items-center font-['Roboto'] ">
                            <div>
                                <div>
                                    <MdMailOutline className='text-xl'/>
                                </div>
                            </div>
                            <div>Info@frikly.com</div>
                        </div>
                        <div className='flex gap-x-3 items-center'>
                            <div>
                                <MdAddCall className='text-xl'/>
                            </div>
                            <div> +91 8469497397</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full xl:gap-x-0 gap-x-20 px-20  xl:px-32  xl:pr-96  flex lg:flex-row flex-col  justify-between items-center mt-10'>
                <div className='md:text-2xl md:leading-[40px] w-56 md:w-full xl:w-2/4 '>Frikly is a one-stop destination for everthing a home needs and assists anyone, anywhere, to get the feel just like your home.</div>
                <div className='flex gap-x-4 lg:mt-0 mt-5'>
                    <FaFacebookF className='text-2xl'/>
                    <FaInstagram className='text-2xl'/>
                    <FaTwitter className='text-2xl'/>
                    <FaLinkedin className='text-2xl'/>
                </div>
            </div>
            <div className="items-end flex justify-center md:mt-16 mt-6 text-xs md:text-sm md:leading-[21px] font-['Roboto'] font-normal lg:mx-0 md:mx-20 mx-3 md:pb-0 pb-8">© Copyright 2021 by Paru Technologies Pvt Ltd</div>
        </div>
        
    </div>
  )
}

export default Footer