import React from 'react'
import Collections from '../assets/collections.svg'
import Coupon from '../assets/coupons.svg'
import Customize from '../assets/customize.svg'
import Delivery from '../assets/delivery.svg'
const Services = () => {
    const services = [
        {
            title: 'Wonderful Collection',
            description: 'Grab your favorites, that suit your taste from a vast array of options',
            image: Collections,
        },
        {
            title: 'Customized Services',
            description: 'Accelerate the process through our customized services.',
            image: Customize,
        },
        {
            title: 'Exclusive Coupons',
            description: 'Get your own promo codes to get exclusive discounts and rewards',
            image: Coupon,
        },
        {
            title: 'Quick Delivery',
            description: 'Fasten the pace of your project with our quick delivery service of Frikly.',
            image: Delivery,
        },

    ]
    return (
        <div className='flex flex-col justify-center items-center pt-5 pb-32 px-20'>
            <div className='text-2xl lg:text-5xl lg:leading-[60px] font-bold text-[#F30505]'>What we offer at Frikly Professional</div>
            <div className='flex lg:flex-row flex-col justify-center gap-x-44 items-center w-full mt-10'>
                {services.map((service, index) => (
                    <div className='flex flex-col md:justify-start justify-center md:items-start items-center '>
                        <div className='flex flex-col md:justify-start justify-center md:items-start items-center'>
                            <img src={service.image} alt="" />
                        </div>
                        <div className={"mt-5 text-2xl leading-[20px] font-bold flex justify-center items-center md:block " + (index === 0 ? "w-20" : "")} >{service.title}</div>
                        <div className="mt-6 text-base leading-[20px] font-light font-['Open_Sans']" >{service.description}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Services