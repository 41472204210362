import React from 'react'
import DesignAssistance from '../assets/design.svg'
import PackagingAssistance from '../assets/packaging.svg'
import WorkTogether from '../assets/work_together.svg'
import Tools from '../assets/tools.svg'
const Assistance = () => {
    const assistances = [
        {
            title: 'Design Assistance',
            description: 'Get expert assistance in creating a brand, improving the overall display of your product to attract customers.',
            image: DesignAssistance
        },
        {
            title: 'Tools for assisting project',
            description: 'With our smart tools, keep track of the latest prices and best selling products , create product lists and much more.',
            image: Tools
        },
        {
            title: 'Packaging Assistance',
            description: 'Get expert assistance in creating a brand, improving the overall display of your product to attract customers.',
            image: PackagingAssistance
        },
        {
            title: 'Work together',
            description: 'Access the same window and work simultaneously using the multiple IDs made for you and your team.',
            image: WorkTogether
        },
        
    ]
    return (
        <div className='flex flex-col justify-center items-center  mt-44 pb-20'>
            <div className='text-[#F53333] md:px-0 px-4 text-2xl  md:ml-0 ml-5 flex justify-center items-center md:text-5xl md:leading-[53.34px] font-bold'>Smart Tools exclusively designed for Professionals</div>
            <div className='md:mt-20 mt-5 flex xl:flex-row flex-col w-full px-20 items-center'>
                {assistances.map((assistance) => (
                    <div className='flex flex-col xl:mt-0 mt-10  justify-center items-center'>
                        <div className=' rounded-[20px] bg-[#506961] md:px-20 md:py-10 px-5 py-7'>
                            <img className='bg-transparent w-20 md:w-full md:h-full ' src={assistance.image} alt="" />
                        </div>
                        <div className='md:mt-0 mt-5 md:text-2xl text-xl md:leading-5 font-bold'>{assistance.title}</div>
                        <div className="font-['Open_Sans] font-light text-base leading-5 text-center mt-7 md:mx-20">{assistance.description}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Assistance