import React from 'react'
import Logo from '../assets/logo.svg'
const Header = () => {
  return (
    <div className=' w-screen bg-white items-center justify-center flex py-5 '>
        <img src={Logo} alt="Logo" className=' md:w-fit w-64 border-b border-black'/>
    </div>
  )
}

export default Header