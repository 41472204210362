import React from 'react'

const Information = ({ image, heading, subHeading, content, buttonText, isSubheading, isRowReverse }) => {
    return (
        <div className='w-full h-full pb-10 bg-[#FEFAE0]'>
            <div className='lg:px-32 pt-10'>
                <div className={"flex mt-10 justify-center items-center lg:items-start lg:justify-between w-full lg:gap-x-10 " + (isRowReverse ? "lg:flex-row-reverse flex-col-reverse" : "lg:flex-row flex-col-reverse")}>
                    <div className='mt-10  w-full h-full flex justify-center items-center flex-col md:px-0 px-5'>
                        <div className='text-xl md:text-3xl lg:text-6xl flex justify-center items-center font-semibold w-full text-[#F30505]'>{heading}</div>
                        <div className={"mt-11  md:text-2xl lg:text-3xl font-semibold text-black " + (!isSubheading ? "hidden": "md:flex lg:block")}>{subHeading}</div>
                        <div className={"font-['Open_Sans'] md:text-xl text-center leading-[40.5px] font-light  " + (isSubheading ? "mt-14" : "mt-9")}>{content}</div>
                        <div className={"bg-[#F30505] w-fit mt-9 md:px-20 px-7 py-2 rounded-2xl font-bold text-white md:text-lg font-['Open_Sans']"}>{buttonText}</div>
                    </div>
                    <img src={image} alt="" className='md:w-[440px] md:h-[600px] md:px-0 px-10 lg:mt-0 my-5 rounded-full' />
                </div>
            </div>
        </div>
    )
}

export default Information