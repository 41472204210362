import Header from "./components/Header";
import Home from "./components/Home";
import Information from "./components/Information";
import Background from './assets/homeBg.png'
import Services from "./components/Services";
import Rewards from "./components/Rewards";
import Brands from "./components/Brands";
import Assistance from "./components/Assistance";
import WelcomeHome from "./components/WelcomeHome";
import Footer from "./components/Footer";
function App() {
  const informationItems = [
    {
      image: 'https://images.pexels.com/photos/1571458/pexels-photo-1571458.jpeg?auto=compress&cs=tinysrgb&w=1600',
      heading: 'Membership-Just for you!',
      subHeading: 'An honour,you made it to Frinkly!',
      content: '‘Frikly Memberships’, a step ahead of everyone - it’s a premium club only for Professionals like you. It is the place to gain access to the inaccessible, to discover rare finds, to be the first to receive news and offers for your chosen projects.',
      buttonText: 'Become a member',
      isSubheading: true ,
      isRowReverse: false,
    },
    {
      image: 'https://images.pexels.com/photos/271816/pexels-photo-271816.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      heading: 'Frikly is a one-stop destination for everything a home needs and assists anyone, anywhere, to get the feel just like home.',
      subHeading: '',
      content: 'Explore and purchase a variety of designs, and categories to suit your needs. We are bringing furniture, home decor, interior and renovation products from all across the world and getting them delivered to you and your clients.',
      buttonText: 'Explore Frikly.com',
      isSubheading: false ,
      isRowReverse: true,
    },
    {
      image: 'https://images.pexels.com/photos/2193600/pexels-photo-2193600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      heading: 'Business Pricing For Professionals',
      subHeading: '',
      content: "Frikly Professionals members receive business-pricing, which lowers the price of millions of products, allowing professionals to scale back the budget of your projects.This price change is only available to Pros, so it won't take effect unless you register yourself as a pro member.",
      buttonText: 'Become a Pro member',
      isSubheading: false ,
      isRowReverse: false,
    }
  ]
  return (
    <div className="w-full h-full ">
      <Header />
      <Home />
      <div className="flex flex-col gap-y-16">
       {informationItems.map((item)=>(
        <Information buttonText={item.buttonText} content={item.content} heading={item.heading} image={item.image} isRowReverse={item.isRowReverse} isSubheading={item.isSubheading} subHeading={item.subHeading} />
       ))}
      </div>
      <Services/>
      <Rewards/>
      <Brands/>
      <Assistance/>
      <WelcomeHome/>
      <Footer/>
    </div>
  );
}

export default App;
